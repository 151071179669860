import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as Info } from "assets/icons/info-i.svg";
import { crInitialCols } from "assets/tableColumns/crInitial";
import Button from "components/Button";
import Card from "components/Card";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import TrialLimit from "components/Modal/TrialLimit";
import Search from "components/Search";
import Table from "components/Table";
import TableTab from "components/Table/TableTab";
import Toast from "components/Toast";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useSearchHistory from "hooks/useSearchHistory";
import useTable from "hooks/useTable";
import useTabs from "hooks/useTabs";
import LoadingPage from "pages/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { crApi, useCrAliMutation, useCrEbayMutation, useGetCrHistoryQuery } from "store/api/crApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { useEbayCrDeleteMutation } from "store/api/watchListApi";
import {
  CrFilterChangePayload,
  handleCrFilterChange,
  handleResetCrEbayData,
  handleResetCrFilters
} from "store/slices/crSlice";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";
import handleEnterClick from "utils/handleEnterClick";
import { GetMarketplace, HISTORY_KEYS } from "utils/localStorage";

enum TABS {
  ebay = "ebay",
  aliexpress = "aliexpress",
}

enum TABLETABS {
  history = "search",
  waitinglist = "progress",
  watchlist = "watchlist"
}

const CompetitorResearchFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    postEbayFilters,
    { isLoading: ebayLoading, isError: ebayError, data: ebayData, status },
  ] = useCrEbayMutation();
  const [
    postAliFilters,
    { isLoading: aliLoading, isError: aliError, data: aliData },
  ] = useCrAliMutation();
  const dispatch = useDispatch();
  const { aliFilters, ebayFilters } = useAppSelector((state) => state.crSlice);
  const [message, setMessage] = useState("");
  const { data: quickSettings } = useGetSettingsQuery();
  const [currentTab, setCurrentTab] = useState<TABS>();
  const isEbay = currentTab === TABS.ebay;
  const search = isEbay ? ebayFilters.competitor : aliFilters.storeName;
  const ebaySearchHistory = useSearchHistory(HISTORY_KEYS.crEbaySearch, search);
  const aliSearchHistory = useSearchHistory(HISTORY_KEYS.crAliSearch, search);
  const navigate = useNavigate();
  const localMarketplace = GetMarketplace();
  const trialLimitModal = useModal();
  const { activeTab, onTabChange } = useTabs<TABLETABS>(TABLETABS.history);
  const { data: historyData, isFetching: historyFetching } = useGetCrHistoryQuery({ type: activeTab }, { refetchOnMountOrArgChange: true });
  const [deleteFromWatchlist] = useEbayCrDeleteMutation()

  useEffect(() => {
    dispatch(handleResetCrEbayData());
    dispatch(handleResetCrFilters());
  }, [])

  useEffect(() => {
    if (searchParams.get("error")) {
      setMessage(searchParams.get("error") || "");
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
    if (aliError || ebayError) {
      setMessage(ErrorCodes.error);
    }
    if (isEbay) {
      if (ebayData?.result.message) {
        setMessage(ebayData?.result.message);
      }
    } else {
      if (aliData?.result.message) {
        setMessage(aliData?.result.message);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliData || ebayData || aliError || ebayError || searchParams, status]);
  useEffect(() => {
    if (message === ErrorCodes.error) {
      toast.error(ErrorMsgs[message]);
    }
    if (isEbay) {

      dispatch(crApi.util.invalidateTags([{ type: "EbayCrHistory" }]));
      if (
        message === ErrorCodes.notPossible ||
        message === ErrorCodes.userNotFound ||
        message === ErrorCodes.noSales ||
        message === ErrorCodes.limitExeeded ||
        message === ErrorCodes.noApi
      ) {
        if (ErrorCodes.noSales) {
          toast.warning(ErrorMsgs[message]);
        } else {
          toast.error(ErrorMsgs[message]);
        }
      }
      if (message === ErrorCodes.usernameChange) {
        toast.error(
          ErrorMsgs[ErrorCodes.usernameChange](ebayData?.result.username || "")
        );
      }

      if (
        message === ErrorCodes.alreadyAddedToQueue ||
        message === ErrorCodes.alreadyToQueue
      ) {

        toast.info(
          <div className="flex flex-col gap-2 items-start bg-white py-3 dark:bg-deepSpace900">
            <p>
              This competitor has been added to our waiting list.
              Sometimes it might take a little longer, but usually, we track the store's sales within a few minutes.
            </p>

          </div>,
          { autoClose: false }
        );
      }

      if (message === ErrorCodes.trialLimitExceeded) {
        trialLimitModal.open();
      }

      if (message === ErrorCodes.ok) {
        ebaySearchHistory.add();
      }
    } else {
      if (message === ErrorCodes.notExist) {
        toast.warning(ErrorMsgs[message]);
      }
      if (message === ErrorCodes.success) {
        navigate({
          pathname: PATH.competitorResearch.aliexpress,
        });
        aliSearchHistory.add();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebayData, aliData, message]);

  useEffect(() => {
    if (searchParams.get("marketplace") === TABS.aliexpress) {
      setCurrentTab(TABS.aliexpress);

      return;
    }
    if (searchParams.get("marketplace") === TABS.ebay) {
      setCurrentTab(TABS.ebay);

      return;
    }
    if (localMarketplace === DASHBOARD_MARKET_PLACES.aliexpress) {
      setCurrentTab(TABS.aliexpress)

      return;
    }
    else {
      setCurrentTab(TABS.ebay)

      return;
    }
  }, [searchParams, quickSettings?.result.sellerSite]);

  const handleFilterChange = ({
    marketplace,
    field,
    newVal,
  }: CrFilterChangePayload) => {
    dispatch(handleCrFilterChange({ marketplace, field, newVal }));
  };

  const onSearchClick = async () => {
    if (
      (isEbay && ebayFilters.competitor === "") ||
      (!isEbay && aliFilters.storeName === "")
    ) {
      toast.error("Please enter the search term!");
    } else {
      if (isEbay) {
        navigate(`${PATH.competitorResearch.ebay}?search=${ebayFilters.competitor}`)
      } else {
        await postAliFilters(aliFilters);
      }
    }
  };

  const onDateChange = (newVal: string) => {
    handleFilterChange({
      marketplace: "ebayFilters",
      field: "dateRange",
      newVal,
    });
  };

  useEffect(() => {
    if (quickSettings?.result?.competitorDay) {
      onDateChange(quickSettings.result?.competitorDay?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSettings]);

  const onSearchChange = (newVal: string) => {
    handleFilterChange({
      marketplace: isEbay ? "ebayFilters" : "aliFilters",
      field: isEbay ? "competitor" : "storeName",
      newVal,
    });
  };



  const handleDeleteFromWatchlist = (searchedUserId: number) => {
    deleteFromWatchlist({
      searchedUserId: searchedUserId
    }).unwrap().then(() => {
      dispatch(crApi.util.invalidateTags([{ type: "EbayCrHistory" }]));
    });

  }


  const { table } = useTable({
    columns: crInitialCols(activeTab, handleDeleteFromWatchlist),
    columnDeps: [activeTab, historyData?.result],
    sort: true,
    data: historyData?.result || [],
    dataDeps: [activeTab, historyData?.result],
  });

  return (
    <LoadingPage loading={ebayLoading || aliLoading}>
      <Header
        className="gap-8 pl-5 pr-10"
        leftContent={
          <>
            <div className="flex gap-[25px] lg:mr-2 max-sm:flex-col max-lg:justify-center">
              <HeaderTab
                title="AliExpress"
                className="min-w-[90px] max-w-[90px]"
                handleClick={() =>
                  setSearchParams({ marketplace: TABS.aliexpress })
                }
                isActive={currentTab === TABS.aliexpress}
              />
              <HeaderTab
                title="eBay"
                className="min-w-[90px]"
                handleClick={() => setSearchParams({ marketplace: TABS.ebay })}
                isActive={currentTab === TABS.ebay}
              />
            </div>
            <div
              className="flex items-center gap-[38px] justify-center w-full max-sm:flex-col"
              onKeyUp={(e) => handleEnterClick(e, onSearchClick)}
            >
              <Search
                search={search}
                handleChange={onSearchChange}
                searchHistory={isEbay ? ebaySearchHistory : aliSearchHistory}

                className="max-w-[550px]"
                placeholder="Search for competitor username or ID"
                htmlFor="search"
              />
              {/* {isEbay ? (
                <Select
                  options={saleDateRangeArr}
                  value={date.toString()}
                  btnClassName="max-h-9 min-w-[6rem]"
                  listWrapperClassName="top-[40px]"
                  handleChange={onDateChange}
                />
              ) : (
                ""
              )} */}
              <Button
                title="Search"
                loading={ebayLoading || aliLoading}
                height="h-9"
                className="max-w-[7.85rem]"
                handleClick={onSearchClick}
              />
            </div>
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo
              videos={isEbay ? helpVideos.cr : helpVideos.crAli}
            />
            {isEbay ? (
              <HeaderIcon
                variant={HeaderVariants.settings}
                tooltipTitle="Competitor Research Settings"
                handleClick={() =>
                  navigate(`${PATH.quickSettings.root}?tab=competitor-settings`)
                }
              />
            ) : ""}
          </>
        }
        leftContentClassName="w-full"
      />
      {!isEbay ?
        (<h1 className="mt-[6rem] text-[28px] text-center font-bold uppercase dark:text-lilac400">
          Search How
          <br /> other sellers are doing
        </h1>)
        :
        <Card className="p-[15px] dark:text-grey100" >
          <div className="flex items-center gap-2.5 mb-2.5">
            <TableTab title="Search History" isActive={activeTab === TABLETABS.history} handleClick={() => onTabChange(TABLETABS.history)} />
            <TableTab title="Waiting List" isActive={activeTab === TABLETABS.waitinglist} handleClick={() => onTabChange(TABLETABS.waitinglist)} />
            <TableTab title="Watchlist" isActive={activeTab === TABLETABS.watchlist} handleClick={() => onTabChange(TABLETABS.watchlist)} />
          </div>
          <div className="overflow-x-auto !overflow-y-visible scrollbar w-full max-h-[calc(100vh-200px)]  max-xl:max-h-[calc(100vh-300px)]" style={{}}>
            <Table
              table={table}
              updateColWidth
              // sort={sort}
              //infoMsg={activeTab === TABS.inProgress ? "As a store is added to our system, we start tracking it’s sales, providing data from this point forward." : undefined}
              styles={{
                px: "px-0",
                style: "relative pr-2",
                //tableClassName: `w-full !overflow-y-visible ${activeTab === TABS.watchlist ? "min-w-[1308px]" : "min-w-[1240px]"}`,
                body: {
                  //rowHeight: activeTab === TABS.watchlist ? "h-[100px]" : "h-[81px]",
                  contHeight: "h-[37px]",
                  rowHeight: "h-[80px]",
                  cont: "px-[16px]"
                },
                empty: {
                  textStyles: "text-sm",
                  rowHeight: "h-[80px]"
                },
                head: {
                  rowHeight: "h-[56.675px]",
                  row: "top-[-1px]",
                  cont: "px-[16px] pl-[16px]"
                }
              }}
              emptyTableMsg={activeTab !== TABLETABS.watchlist ? "Search for your first eBay Competitor by searching for it’s username or ID!" : ""}
              isLoading={historyFetching}
            />
            {historyData?.result && historyData?.result.length > 0 && !historyFetching && activeTab !== TABLETABS.watchlist ? (
              <div className="flex items-center gap-2 pl-2.5 pb-2 pt-[2px]">
                <div className="flex items-center justify-center w-[22px] h-[22px]">
                  <Info className="fill-darkBlue dark:fill-grey100 cursor-pointer w-[13px] h-[13px]" />
                </div>
                <p className="text-xs font-normal">
                  {activeTab === TABLETABS.waitinglist ? "Only the most recent 50 stores in your waiting list are shown here" :
                    "Only the most recent 50 stores from your search history are displayed here. Save the ones you want to keep or remember to your watchlist to avoid losing them."}

                </p>
              </div>
            ) : ""}
          </div>
        </Card>}
      <Toast />
      <TrialLimit helpers={trialLimitModal} />
    </LoadingPage>
  );
};

export default CompetitorResearchFilters;
